import { NextPage } from 'next';
import React from 'react';
import { accessControl } from 'stars-access-control';
import { useUserDataContext } from 'stars-components/lib/components/providers/UserDataContextProvider/hooks/useUserDataContext';
import { Dashboard } from 'components/Dashboard';
import { WelcomePage } from 'components/WelcomePage';
import { getServerSideProps } from 'helpers/getServerSideProps';
import { useIsOnboardingCompleted } from 'hooks/useIsOnboardingCompleted';

const Home: NextPage = () => {
  const isOnboardingCompleted = useIsOnboardingCompleted();
  const { isSuperUser } = useUserDataContext();

  const {
    isManager,
    isAdvertiser,
    isAdvertiserAccountManager,
    isPublisher,
    isPublisherAccountManager,
    isRtbDsp,
    isRtbSsp,
  } = accessControl.role;

  const shouldShowDashboard =
    isSuperUser ||
    isManager() ||
    isRtbDsp() ||
    isRtbSsp() ||
    isOnboardingCompleted;

  if (shouldShowDashboard) {
    return (
      <Dashboard
        isAdvertiser={isAdvertiser() || isAdvertiserAccountManager()}
        isPublisher={isPublisher() || isPublisherAccountManager()}
      />
    );
  }

  return <WelcomePage isAdvertiser={isAdvertiser()} />;
};

export { getServerSideProps };

export default Home;
